@charset "utf-8";

/* Виджет авторизации (МИДИ) */
.usersAuthPanelMidiSignInBlockWrap 
{
	position: fixed;
	top: 53%;
	left: 50%;
	transform: translate(-50%,-50%);
	background-color: #fff;

	.usersAuthPanelMidiSignInBlockClip 
	{
		position: absolute;
		z-index: -1;
		top: -12em;
		left: 50%;
		display: block;
		width: 15em;
		height: 4em;
		transform: translateX(-50%);
		background-image: url('../imgs/common/su_logo.svg');
		background-repeat: no-repeat;
		-webkit-background-size: contain;
		background-size: contain;
	}
	.usersAuthPanelMidiSignInBlockClip:before 
	{
		content: '';
		position: absolute;
		top: -1.3em;
		left: -16.5em;
		display: inline-block;
		width: 16.5em;
		height: 100em;
		background-image: url('../imgs/auth/man.svg');
		background-repeat: no-repeat;
		-webkit-background-size: contain;
		background-size: contain;
	}
	.usersAuthPanelMidiSignInBlockClip:after 
	{
		content: '';
		position: absolute;
		top: -.3em;
		right: -13em;
		display: inline-block;
		width: 7em;
		height: 18em;
		background-image: url('../imgs/auth/girl.png');
		background-repeat: no-repeat;
		background-size: contain;
	}

	/* Форма входа */
	.usersAuthPanelMidiSignIn
	{
		width: 48em;
		height: auto;
		padding: 4.2em 3.5em 5em;
		text-align: center;
		box-shadow: 0 0 92px rgba(0, 0, 0, .1);
		z-index: 10;
		position: relative;
		background-color: #fff;

		.usersAuthPanelMidiSignInTitle 
		{
			margin-bottom: 4em;
			h3 
			{
				font-size: 1.6em;
				font-weight: 700;
				line-height: 1.5;
			}
		}
		.usersAuthPanelMidiSignInInput 
		{
			.inpText 
			{
				font-size: 1.2em;
			    font-weight: bold;
				display: inline-block;
			    width: 100%;
			    margin: 5px auto;
			    margin-bottom: 1.5em;
			    padding: 1em;
			    color: #1e1a29;
			    color: #000;
			    border: 1px solid #bbb;
			    border: 1px solid #d7dbe3 !important;
			    background: #f9f9f9 !important;
			}
			.defaultButtonUI 
			{
				font-size: 1.5em;
			    width: 100%;
			    padding: 1em;
			    margin: 0;
			}
		}
	}

	/* Форма выхода */
	.usersAuthPanelMidiSignOut
	{
		width: 48em;
		height: auto;
		padding: 4.2em 3.5em 5em;
		text-align: center;
		box-shadow: 0 0 92px rgba(0, 0, 0, .1);
		z-index: 10;
		position: relative;
		background-color: #fff;

		p
		{
			font-size: 1.2em;
		}
		.defaultButtonUI 
		{
			font-size: 1.5em;
			margin-bottom: 0;
		    width: 40%;
		    padding: 1em;
		}
	}
}

@media all and (max-height: 820px) 
{
	.usersAuthPanelMidiSignInBlockWrap 
	{
		top: 60vh; 
	}
}
@media all and (max-height: 620px) 
{
	.usersAuthPanelMidiSignInBlockWrap .usersAuthPanelMidiSignInBlockClip 
	{
		top: -7em;

		&:after,
		&:before 
		{
			display: none;
		}
	}
}
@media all and (max-width: 700px) 
{
	.usersAuthPanelMidiSignInBlockWrap 
	{
		position: relative;
		top: 0;
		left: 0;
		transform: none;
		width: 100vw;
		min-height: 100vh;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.usersAuthPanelMidiSignInBlockWrap .usersAuthPanelMidiSignInBlockClip 
	{
		position: relative;
		z-index: 1;
		top: 0;
		left: 0;
		width: 31em;
		height: 12em;
		transform: none;
	}
	.usersAuthPanelMidiSignInBlockWrap .usersAuthPanelMidiSignIn 
	{
		box-shadow: none;
		padding-left: 1.5em;
		padding-right: 1.5em;
	}
	.usersAuthPanelMidiSignInBlockWrap .usersAuthPanelMidiSignIn .usersAuthPanelMidiSignInTitle h3 
	{
		font-size: 3em;
	}
	.usersAuthPanelMidiSignInBlockWrap .usersAuthPanelMidiSignIn .usersAuthPanelMidiSignInInput .inpText 
	{
		font-size: 2.2em;
	}
	.usersAuthPanelMidiSignInBlockWrap .usersAuthPanelMidiSignIn .usersAuthPanelMidiSignInInput .defaultButtonUI 
	{
		font-size: 3em;
		margin-top: 11vh;
	}
	.usersAuthPanelMidiSignInBlockWrap .usersAuthPanelMidiSignInBlockClip:before,
	.usersAuthPanelMidiSignInBlockWrap .usersAuthPanelMidiSignInBlockClip:after 
	{
		display: none;
	}
	.app-auth 
	{
		background-image: none;
		font-size: .5em;
	}
} 